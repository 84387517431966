


















































































































































































































































































.form::v-deep {
 .modal-cadastro-form-col-imput {
    padding-top: 0;
    padding-bottom: 0;
  }
 
 .v-text-field.v-text-field--enclosed .v-text-field__details {
   padding: 0
 }
}

.list {
  padding-left: 0;
  &-item {
    display: inline-block;
    list-style: none;
    cursor: pointer;
    color: #5076ff;

    &.active {
      color: #9797ad;
    }
  }
}
